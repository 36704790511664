import React, { useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { useAuth } from "@clerk/clerk-react";
import { submitResyCredentials } from "../api";
import { BiHide, BiShow } from "react-icons/bi";

const CredentialsPopup = ({ user, handleUserReload, setPopUp }) => {
  const { getToken } = useAuth();
  const [email, setEmail] = useState(user.resy_email || "");
  const [password, setPassword] = useState([]);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const popupRef = useRef(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleEmailPaste = (e) => {
    setEmail(e.clipboardData.getData("text/plain"));
  };

  const handlePasswordPaste = (e) => {
    setPassword(e.clipboardData.getData("text/plain"));
  };
  const handleCancel = (e) => {
    setPopUp(false);
  };

  const handleSubmit = () => {
    if (!email || !password || password.length === 0) {
      return;
    }
    const request_object = {
      resy_email: email,
      resy_password: password,
    };
    async function submitAndReload() {
      try {
        await submitResyCredentials(request_object, getToken);
        toast.success("Set credentials successfully!");
      } catch {
        toast.error("Failed submitting credentials");
      }
      setPopUp(false);
      handleUserReload();
    }
    submitAndReload();
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={popupRef}
        className="bg-white p-4 min-w-[400px] rounded-lg shadow-xl"
      >
        <div className="flex justify-end">
          <button
            className="flex items-center ml-2 justify-center text-black py-2 px-2 rounded-full focus:outline-none"
            onClick={handleCancel}
          >
            <FaTimes size={18} />
          </button>
        </div>
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">
            {user.resy_email ? "Edit" : "Submit"} your Credentials
          </h2>
          <p className="text-center text-gray-500 max-w-sm mb-4">
            If you don't typically use email and password for Resy, click Forgot
            Password to set one.
          </p>
          <div>
            <input
              type="text"
              placeholder="Enter Email"
              value={email}
              onChange={handleEmailChange}
              onPaste={handleEmailPaste}
              className="border border-gray-300 px-4 py-2 rounded-md mb-4"
            />
          </div>
          <div className="relative">
            <input
              name="pwd"
              placeholder="Enter Password"
              type={isRevealPwd ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              onPaste={handlePasswordPaste}
              className="border border-gray-300 px-4 py-2 rounded-md mb-4"
            />
            {isRevealPwd && (
              <BiHide
                size={18}
                title={"Hide password"}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
                className="absolute w-6 right-8 top-3"
              />
            )}
            {!isRevealPwd && (
              <BiShow
                size={18}
                title={"Show password"}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
                className="absolute w-6 right-8 top-3"
              />
            )}
          </div>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default CredentialsPopup;
