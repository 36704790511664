import React from "react";
import emptyTable from "../assets/empty_table.png"; // import the empty table image

const NoPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <img
        src={emptyTable}
        alt="Empty Table"
        className="w-1/2 h-1/2 md:w-1/3 md:h-1/3 lg:w-1/4 lg:h-1/4"
      />
      <h1 className="text-4xl md:text-6xl font-bold text-gray-700 mt-5">
        404 - Not Found
      </h1>
      <p className="text-xl md:text-2xl text-gray-500 mt-2">
        Sorry, the page you are looking for does not exist.
      </p>
    </div>
  );
};

export default NoPage;
