import React from "react";
import { FaUtensils, FaList, FaTable, FaPen } from "react-icons/fa";
import { PiChair, PiChairFill, PiKnifeFill, PiKnife } from "react-icons/pi";
import { LuUtensils } from "react-icons/lu";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const SideMenu = ({ setPopUp }) => {
  function trigger() {
    setPopUp(true);
  }
  const location = useLocation().pathname;
  return (
    <div className="w-1/4 max-w-xs min-w-min flex flex-col items-center pt-10 pl-5 rounded-lg border-r-2 md:items-start">
      <Link
        to={"/available"}
        className="m-4 hover:bg-gray-300 py-3 px-3 rounded-full flex items-center"
      >
        {location === "/available" ? (
          <FaUtensils className="text-blue-800 flex-shrink-0" size={25} />
        ) : (
          <LuUtensils className="text-blue-800 flex-shrink-0" size={25} />
        )}
        <span
          className={`ml-2 text-blue-800 text-md md:block hidden whitespace-nowrap ${
            location === "/available" ? "font-bold" : ""
          }`}
        >
          Available Reservations
        </span>
      </Link>
      <Link
        to={"/requests"}
        className="m-4 hover:bg-gray-300 py-3 rounded-full flex items-center px-3 "
      >
        {location === "/requests" ? (
          <PiKnifeFill className="text-blue-800 flex-shrink-0 mr-2" size={25} />
        ) : (
          <PiKnife className="text-blue-800 flex-shrink-0 mr-2" size={25} />
        )}
        <span
          className={`ml-2 text-blue-800 text-md md:block hidden whitespace-nowrap ${
            location === "/requests" ? "font-bold" : ""
          }`}
        >
          Your Requests
        </span>
      </Link>
      <Link
        to={"/reservations"}
        className="m-4 hover:bg-gray-300 py-3 px-3 rounded-full flex items-center"
      >
        {location === "/reservations" ? (
          <PiChairFill className="text-blue-800 flex-shrink-0" size={25} />
        ) : (
          <PiChair className="text-blue-800 flex-shrink-0" size={25} />
        )}
        <span
          className={`ml-2 text-blue-800 text-md md:block hidden whitespace-nowrap ${
            location === "/reservations" ? "font-bold" : ""
          }`}
        >
          Your Reservations
        </span>
      </Link>
      <button
        onClick={trigger}
        className="m-4 bg-green-500 hover:bg-green-600 py-3 px-3 rounded-full flex items-center md:ml-10"
      >
        <FaPen className="text-white flex-shrink-0" size={25} />
        <span className="ml-2 text-white text-md md:block hidden whitespace-nowrap">
          Make a request
        </span>
      </button>
    </div>
  );
};

export default SideMenu;
