import { React, useState, useContext } from "react";
import { FaCheck, FaUtensils } from "react-icons/fa";
import { BiLinkExternal } from "react-icons/bi";
import { claimReservation } from "../api";
import RCard from "./RCard";
import { toast } from "react-toastify";
import { useAuth } from "@clerk/clerk-react";

function AvailableReservationCard({
  reservation,
  restaurant,
  handleReservationReload,
  handleAvailableReservationReload,
}) {
  const { getToken } = useAuth();
  const id = reservation && reservation.id;
  const external_link =
    restaurant &&
    `https://resy.com/cities/${restaurant.city_code}/${restaurant.url_slug}`;

  const [confirmed_claim, setConfirmedClaim] = useState(false);

  const handleClick = () => {
    if (confirmed_claim) {
      async function claimAndReload() {
        const claim_success = await claimReservation(id, getToken);
        await handleReservationReload();
        await handleAvailableReservationReload();
        toast.success(
          "Claimed your reservation! It should be in your reservations page."
        );
      }
      claimAndReload();
      setConfirmedClaim(false);
    } else {
      setConfirmedClaim(true); // Change to confirmed state on first click
    }
  };

  const button_row = (
    <div className="flex flex-row justify-end mt-3">
      <button
        className="flex items-center justify-center border-2 border-gray-500 hover:bg-gray-500 hover:text-white hover:border-gray-500 text-gray-500 py-2 px-2 rounded-full focus:outline-none"
        onClick={() => window.open(external_link, "_blank")}
      >
        <BiLinkExternal size={20} />
      </button>
      <button
        className="flex items-center ml-2 justify-center border-2 border-green-400 hover:bg-green-400 hover:text-white hover:border-green-400 text-green-400 py-2 px-2 rounded-full focus:outline-none"
        onClick={handleClick}
        onBlur={() => setConfirmedClaim(false)}
      >
        {confirmed_claim ? (
          <FaCheck className="mr-2" size={20} />
        ) : (
          <FaUtensils className="mr-2" size={20} />
        )}
        {confirmed_claim ? "Confirm Claim" : "Claim"}
      </button>
    </div>
  );

  return (
    <RCard
      r_data={reservation}
      r_header={"RESERVATION"}
      restaurant={restaurant}
      buttons={button_row}
    />
  );
}

export default AvailableReservationCard;
