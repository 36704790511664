import { React } from "react";
import { FaUserFriends, FaClock } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";

const StatusColorMap = {
  pls_confirm: "blue",
  booked: "green",
  confirmed: "green",
  pending: "blue",
  active: "blue",
  completed: "green",
};

const getStatusColor = (status) => {
  const color = StatusColorMap[status] || "red";

  switch (color) {
    case "blue":
      return "rgb(191 219 254)";
    case "green":
      return "rgb(220 252 231)";
    case "red":
      return "rgb(254 202 202)";
    default:
      return color;
  }
};

const getStatusTextColor = (status) => {
  const color = StatusColorMap[status] || "red";

  switch (color) {
    case "blue":
      return "rgb(37 99 235)";
    case "green":
      return "rgb(22 163 74)";
    case "red":
      return "rgb(220 38 38)";
    default:
      return color;
  }
};

function RCard({ r_data, r_header, restaurant, buttons }) {
  const {
    party_size,
    use_tablestake_executor,
    date_time,
    status,
    owner,
    holder,
  } = r_data;

  const isTablestake = (holder && owner != holder) || use_tablestake_executor;
  const imageParam = restaurant && restaurant.tablestake_image;
  const restaurantName = restaurant ? restaurant.name : "";
  return (
    <div className="mt-5 flex bg-white shadow-lg w-full max-w-md rounded-lg overflow-hidden flex-col sm:flex-row">
      <div
        className="bg-gray-400 h-72 w-full sm:w-2/5"
        style={{
          backgroundImage: imageParam ? `url(${imageParam})` : "none",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>

      <div className="p-4 flex flex-col justify-between w-full sm:w-3/5">
        <div>
          <h4 className="text-xs text-left underline text-gray-500">
            {r_header}
          </h4>
          <div className="flex flex-row justify-between">
            <div>
              <h2 className="text-lg font-bold mb-2 text-left">
                {restaurantName}
              </h2>
            </div>
            <div
              className={`rounded-md w-24 h-7 flex-shrink-0 flex items-center justify-center`}
              style={{ backgroundColor: getStatusColor(status.toLowerCase()) }}
            >
              <span
                className={`text-xs font-bold text-center`}
                style={{ color: getStatusTextColor(status.toLowerCase()) }}
              >
                {status}
              </span>
            </div>
          </div>
          <p className="text-sm text-gray-700 text-left">
            <FaUserFriends className="inline-block mr-1" size={25} />{" "}
            {party_size} people
          </p>
          <p className="text-sm text-gray-700 text-left mt-2">
            <FaClock className="inline-block mr-1" size={25} />{" "}
            {new Date(date_time).toLocaleString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </p>

          <p className="text-sm text-gray-700 text-left mt-2">
            <MdAccountCircle className="inline-block mr-1" size={28} />{" "}
            {isTablestake ? "In a tablestake account" : "In your account"}
          </p>
        </div>
        {buttons}
      </div>
    </div>
  );
}

export default RCard;
