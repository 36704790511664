import { React, useState, useEffect } from "react";
import { FaTimes, FaInfo, FaCheck } from "react-icons/fa";
import { MdRsvp } from "react-icons/md";
import { BiLinkExternal } from "react-icons/bi";
import { cancelReservation, confirmReservation } from "../api";
import RCard from "./RCard";
import { toast } from "react-toastify";
import { useAuth } from "@clerk/clerk-react";

function ReservationCard({ reservation, restaurant, handleReservationReload }) {
  const {
    party_size,
    use_tablestake_executor,
    date_time,
    status,
    share_link,
    cancellation_cutoff,
    cancellation_fee,
    holder,
    owner,
    id,
  } = reservation;
  const { getToken } = useAuth();

  const external_link =
    restaurant &&
    `https://resy.com/cities/${restaurant.city_code}/${restaurant.url_slug}`;

  const [confirmed_cancel, setConfirmedCancel] = useState(false);
  const is_curr = new Date(reservation.date_time) > new Date();
  const isTablestake = (holder && owner != holder) || use_tablestake_executor;
  const handleCancelClick = () => {
    if (confirmed_cancel) {
      async function cancelAndReload() {
        const cancel_success = await cancelReservation(id, getToken);
        handleReservationReload();
        if (cancel_success) {
          if (isTablestake) {
            toast.success("Cancelled your reservation!");
          } else {
            toast.success(
              `Marked your reservation as cancelled! Since this reservation is in your account, please remember to cancel through your Resy account.`
            );
          }
        } else {
          toast.error("Failed to cancel your reservation! Reload?");
        }
      }
      cancelAndReload();
      setConfirmedCancel(false);
    } else {
      setConfirmedCancel(true); // Change to confirmed state on first click
    }
  };

  const handleConfirmClick = () => {
    async function confirmAndReload() {
      const confirm_success = await confirmReservation(id, getToken);
      handleReservationReload();
      if (confirm_success) {
        toast.success("Confirmed your reservation!");
      } else {
        toast.error("Failed to cancel your reservation! Reload?");
      }
    }
    confirmAndReload();
  };

  const curr_date = new Date();
  const button_row = (
    <div className="flex flex-row justify-end mt-3">
      {["BOOKED", "PLS_CONFIRM", "CONFIRMED"].includes(status) && is_curr && (
        <button
          className="flex ml-2 items-center justify-center border-2 border-green-400 hover:bg-green-400 hover:text-white hover:border-green-400 text-green-400 py-2 px-2 rounded-full focus:outline-none"
          onClick={() => window.open(share_link, "_blank")}
        >
          <MdRsvp size={20} />
        </button>
      )}
      <button
        className="flex ml-2 items-center justify-center border-2 border-gray-500 hover:bg-gray-500 hover:text-white hover:border-gray-500 text-gray-500 py-2 px-2 rounded-full focus:outline-none"
        onClick={() => window.open(external_link, "_blank")}
      >
        <BiLinkExternal size={20} />
      </button>
      {["BOOKED", "PLS_CONFIRM", "CONFIRMED"].includes(status) &&
        is_curr &&
        curr_date < new Date(cancellation_cutoff) && (
          <button
            className="flex items-center ml-2 justify-center border-2 border-red-400 hover:bg-red-400 hover:text-white hover:border-red-400 text-red-400 py-2 px-2 rounded-full focus:outline-none"
            onClick={handleCancelClick}
            onBlur={() => setConfirmedCancel(false)}
          >
            {confirmed_cancel ? <FaCheck size={20} /> : <FaTimes size={20} />}
          </button>
        )}
      {["PLS_CONFIRM"].includes(status) &&
        is_curr &&
        curr_date < new Date(cancellation_cutoff) && (
          <button
            className="flex items-center ml-2 justify-center border-2 border-blue-400 hover:bg-blue-400 hover:text-white hover:border-blue-400 text-blue-400 py-2 px-2 rounded-full text-xs focus:outline-none"
            onClick={handleConfirmClick}
          >
            CONFIRM
          </button>
        )}
    </div>
  );

  return (
    <RCard
      r_data={reservation}
      r_header={"RESERVATION"}
      restaurant={restaurant}
      buttons={button_row}
    />
  );
}

export default ReservationCard;
