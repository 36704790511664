import AvailableReservationCard from "./AvailableReservationCard";
import "../App.css";
import EmptyState from "./EmptyState";
import { useOutletContext } from "react-router-dom";
import loading_gif from "../assets/loading.gif";

function AvailableReservationFeed() {
  const {
    available_reservations,
    restaurant_data,
    handleRequestReload,
    handleReservationReload,
    handleAvailableReservationReload,
    loading,
  } = useOutletContext();

  const isLoading = loading.available !== undefined ? loading.available : true;

  available_reservations.sort(
    (a, b) => new Date(a.date_time) - new Date(b.date_time)
  );

  return (
    <div className="flex flex-col overflow-y-auto items-center no-scrollbar">
      <h1 className="text-2xl font-bold mb-4"> Available Reservations</h1>
      {!isLoading &&
        available_reservations.length === 0 &&
        EmptyState({
          handleRequestReload: handleRequestReload,
          isRequest: false,
        })}
      {isLoading && <img src={loading_gif} className="opacity-50" />}
      {!isLoading &&
        available_reservations !== null &&
        available_reservations.map((res) => (
          <AvailableReservationCard
            key={res.id}
            reservation={res}
            restaurant={restaurant_data[res.venue_id]}
            handleReservationReload={handleReservationReload}
            handleAvailableReservationReload={handleAvailableReservationReload}
          />
        ))}
    </div>
  );
}

export default AvailableReservationFeed;
