import React, { useContext, useState, useRef, useEffect } from "react";
import {
  insertReservationRequest,
  updateReservationRequest,
  getRestaurantReleaseInfo,
} from "../api";
import { UserContext } from "../contexts";
import { FaTimes, FaClock } from "react-icons/fa";
import { toast } from "react-toastify";
import { useAuth } from "@clerk/clerk-react";
import loading_gif from "../assets/loading.gif";

import RestaurantCard from "./RestaurantCard";

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function RequestPopup({
  handleRequestReload,
  setPopUp,
  restaurantList,
  existingRequest,
  setRestaurantIds,
  restaurant_data,
}) {
  const currUser = useContext(UserContext);
  const { getToken } = useAuth();
  const [stage, setStage] = useState(1); // Stage 1: choose restaurant, Stage 2: choose date and time
  const [restaurant, setRestaurant] = useState(
    existingRequest.venue_id
      ? getKeyByValue(restaurantList, existingRequest.venue_id)
      : ""
  );
  const [date, setDate] = useState(
    existingRequest.date_time ? existingRequest.date_time.split("T")[0] : ""
  );
  const [time, setTime] = useState(
    existingRequest.date_time
      ? existingRequest.date_time.split("T")[1].slice(0, 5)
      : "18:30"
  );
  const [numberOfPeople, setNumberOfPeople] = useState(
    existingRequest.party_size ? existingRequest.party_size : 2
  );
  const [useMyAccount, setUseMyAccount] = useState(
    existingRequest.use_tablestake_executor
      ? !existingRequest.use_tablestake_executor
      : currUser.resy_email !== null
  );
  const [searchResults, setSearchResults] = useState([]);
  const [releaseInfo, setReleaseInfo] = useState([]);
  const searchInputRef = useRef(null);
  const popupRef = useRef(null);

  const handleNextStage = () => {
    setStage(stage + 1);
  };

  const handlePreviousStage = () => {
    setStage(stage - 1);
  };

  const handleNextRestaurant = () => {
    if (restaurant && restaurantList.hasOwnProperty(restaurant)) {
      async function getReleaseAndNext() {
        if (!existingRequest) {
          setRestaurantIds((old_list) => [
            ...old_list,
            restaurantList[restaurant],
          ]);
        }
        const release = await getRestaurantReleaseInfo(
          restaurantList[restaurant],
          getToken
        );
        setReleaseInfo(release);
        handleNextStage();
      }
      getReleaseAndNext();
    }
  };

  const handleConfirmRestaurant = () => {
    const rest = restaurant_data[restaurantList[restaurant]];
    const date_offset =
      releaseInfo && releaseInfo.length !== 0
        ? releaseInfo[0].daily_release_offset
        : rest
        ? rest.daily_release_offset
        : 0;
    const curr_date = new Date();
    curr_date.setDate(curr_date.getDate() + date_offset);
    if (!existingRequest || !existingRequest.hasOwnProperty("date_time")) {
      setDate(
        curr_date.toLocaleDateString("sv-SE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      );
    }
    handleNextStage();
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };

  const handleNumberOfPeopleChange = (e) => {
    setNumberOfPeople(parseInt(e.target.value, 10));
  };

  const handleUseMyAccountChange = () => {
    setUseMyAccount(!useMyAccount);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setRestaurant(value);
    // Filter the list of items based on the search value
    const results = Object.keys(restaurantList).filter((item) =>
      item.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResults(results.slice(0, 10));
  };

  const handleSearchResultClick = (value) => {
    setRestaurant(value);
    setSearchResults([]);
  };

  const handleCancel = (e) => {
    setSearchResults([]);
    setStage(1);
    setPopUp(false);
  };

  const handleSubmit = () => {
    if (
      !restaurantList.hasOwnProperty(restaurant) ||
      !date ||
      !time ||
      !numberOfPeople ||
      !currUser
    ) {
      return;
    }
    const request_object = {
      requester: currUser,
      party_size: numberOfPeople,
      venue_id: restaurantList[restaurant],
      use_tablestake_executor: false, //!useMyAccount,
      date_time: date + " " + time,
    };
    async function submitAndReload() {
      if (currUser.resy_email === null) {
        toast.info(
          "Please add your resy credentials to your account. Click the button in the top bar!"
        );
        return;
      }
      if (existingRequest && existingRequest.id) {
        delete request_object["requester"];
        await updateReservationRequest(
          existingRequest.id,
          request_object,
          getToken
        );
      } else {
        const response = await insertReservationRequest(
          request_object,
          getToken
        );
        if (response) {
          toast.success("Request submitted successfully!");
        } else {
          toast.error("Request failed to submit.");
        }
      }
      setPopUp(false);
      handleRequestReload();
    }
    submitAndReload();
  };

  const renderStage1 = () => (
    <>
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Choose a restaurant</h2>
        {/* Restaurant selection code here */}
        <div>
          <input
            type="text"
            value={restaurant}
            onChange={handleSearchChange}
            ref={searchInputRef}
            className="border border-gray-300 px-4 py-2 rounded-md mb-4"
          />
          {searchResults.length > 0 && (
            <ul className="absolute bg-white border border-gray-300 rounded-md shadow-md w-64">
              {searchResults.map((result) => (
                <li
                  key={result}
                  onClick={() => handleSearchResultClick(result)}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                >
                  {result}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button
          onClick={handleNextRestaurant}
          className="px-4 py-2 bg-blue-500 text-white rounded-md"
        >
          Next
        </button>
      </div>
    </>
  );

  function renderStage2() {
    const rest = restaurant_data[restaurantList[restaurant]];
    const date_offset =
      releaseInfo && releaseInfo.length !== 0
        ? releaseInfo[0].daily_release_offset
        : rest
        ? rest.daily_release_offset
        : 0;
    const curr_date = new Date();
    curr_date.setDate(curr_date.getDate() + date_offset);
    const additionalInfo = (
      <>
        <p className="text-xs text-gray-700 text-left mt-2">
          <FaClock className="inline-block mr-1" size={15} /> Next available
          reservation release should be{" "}
          {curr_date.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}{" "}
          (confirm via resy site)
        </p>
      </>
    );
    return (
      <>
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Confirm Restaurant</h2>
          {!rest && (
            <div className="flex justify-center">
              <img src={loading_gif} className="opacity-50" />
            </div>
          )}
          {rest && (
            <RestaurantCard
              restaurant={rest}
              includePopup={false}
              additionalInfo={additionalInfo}
            />
          )}
          <div className="mt-3">
            <button
              onClick={handlePreviousStage}
              className="mr-2 px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Back
            </button>
            <button
              onClick={handleConfirmRestaurant}
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Next
            </button>
          </div>
        </div>
      </>
    );
  }

  function renderStage3() {
    return (
      <>
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Some more details</h2>
          <h2 className="text-xl text-left mb-4">
            When would you like a table?
          </h2>
          {/* Date selection code here */}
          <input
            type="date"
            value={date}
            onChange={handleDateChange}
            className="border border-gray-300 px-4 py-2 rounded-md mb-4"
          />
          {/* Time selection code here */}
          <select
            value={time}
            onChange={handleTimeChange}
            className="border border-gray-300 px-4 py-2 rounded-md mb-4"
          >
            <option value="">Select a time</option>
            {/* Generate time options every 30 minutes */}
            {Array.from({ length: 48 }).map((_, index) => {
              const timeValue = `${Math.floor(index / 2)
                .toString()
                .padStart(2, "0")}:${index % 2 === 0 ? "00" : "30"}`;
              return <option value={timeValue}>{timeValue}</option>;
            })}
          </select>
          <h2 className="text-xl text-left mb-4">And for how many?</h2>
          {/* Number of people selection code here */}
          <input
            type="number"
            value={numberOfPeople}
            min="2"
            max="6"
            onChange={handleNumberOfPeopleChange}
            className="border border-gray-300 px-4 py-2 rounded-md mb-4"
          />
          {/* Use my account switch code here
          <div className="flex  mb-4">
            <span>Use my account</span>
            <input
              type="checkbox"
              checked={useMyAccount}
              disabled={currUser.resy_email === null}
              onChange={handleUseMyAccountChange}
              title={
                currUser.resy_email === null
                  ? "Add resy credentials to use!"
                  : ""
              }
              className="form-checkbox ml-2 h-5 w-5 text-blue-500"
            />
          </div> */}
          <div>
            <button
              onClick={handlePreviousStage}
              className="mr-2 px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Back
            </button>
            <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
            >
              Confirm Request
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={popupRef}
        className="bg-white p-4 min-w-[400px] rounded-lg shadow-xl"
      >
        <div className="flex justify-end">
          <button
            className="flex items-center ml-2 justify-center text-black py-2 px-2 rounded-full focus:outline-none"
            onClick={handleCancel}
          >
            <FaTimes size={18} />
          </button>
        </div>
        {stage === 1 && renderStage1()}
        {stage === 2 && renderStage2()}
        {stage === 3 && renderStage3()}
      </div>
    </div>
  );
}

export default RequestPopup;
