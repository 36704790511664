import React, { useEffect, useState } from "react";
import SideMenu from "../components/SideMenu";
import TopBar from "../components/TopBar";
import CredentialsPopup from "../components/CredentialsPopup";
import RequestPopup from "../components/RequestPopup";
import {
  getReservationRequests,
  getRestaurantIds,
  getRestaurantDetails,
  getReservations,
  getAvailableReservations,
  getFeaturedRestaurants,
  getGeo,
  getUser,
} from "../api";
import { RestaurantContext, UserContext } from "../contexts";
import { ToastContainer } from "react-toastify";
import FeaturedRestaurantFeed from "../components/FeaturedRestaurantFeed";
import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import loading_gif from "../assets/loading.gif";
import { inject } from "@vercel/analytics";

inject();

function App() {
  const { getToken } = useAuth();
  const [user, setUser] = useState(null);
  const [rest_data, setRestData] = useState([]);
  const [rest_ids, setRestIds] = useState({});
  const [user_requests, setUserRequests] = useState([]);
  const [user_reservations, setUserReservations] = useState([]);
  const [available_reservations, setAvailableReservations] = useState([]);
  const [request_reload, setRequestReload] = useState(false);
  const [user_reload, setUserReload] = useState(false);
  const [reservation_reload, setReservationReload] = useState(false);
  const [available_reservation_reload, setAvailableReservationReload] =
    useState(false);
  const [show_popup, setPopUp] = useState(false);
  const [show_credentials, setCredentials] = useState(false);
  const [which_page, setPage] = useState("requests");
  const [user_geo, setUserGeo] = useState({});
  const [featured_restaurants, setFeaturedRestaurants] = useState([]);
  const [loading, setLoading] = useState({});
  const [more_restaurant_ids, setRestaurantIds] = useState([]);

  function handleRequestReload() {
    setRequestReload(!request_reload);
  }
  function handleReservationReload() {
    setReservationReload(!reservation_reload);
  }
  function handleAvailableReservationReload() {
    setAvailableReservationReload(!available_reservation_reload);
  }
  function handleUserReload() {
    setUserReload(!user_reload);
  }

  useEffect(() => {
    async function fetchData() {
      setLoading((loading) => ({
        ...loading,
        ...{ request: true },
      }));
      const reqs = await getReservationRequests(getToken);
      setUserRequests(reqs);
      setLoading((loading) => ({
        ...loading,
        ...{ request: false },
      }));
    }
    fetchData();
  }, [request_reload]);

  useEffect(() => {
    async function fetchData() {
      setLoading((loading) => ({
        ...loading,
        ...{ user: true },
      }));
      const user = await getUser(getToken);
      setUser(user);
      setLoading((loading) => ({
        ...loading,
        ...{ user: false },
      }));
    }
    fetchData();
  }, [user_reload]);

  useEffect(() => {
    async function fetchData() {
      setLoading((loading) => ({
        ...loading,
        ...{ reservation: true },
      }));
      const reqs = await getReservations(getToken);
      setUserReservations(reqs);
      setLoading((loading) => ({
        ...loading,
        ...{ reservation: false },
      }));
    }
    fetchData();
  }, [reservation_reload]);

  useEffect(() => {
    async function fetchData() {
      setLoading((loading) => ({
        ...loading,
        ...{ available: true },
      }));
      const reqs = await getAvailableReservations(getToken);
      setAvailableReservations(reqs);
      setLoading((loading) => ({
        ...loading,
        ...{ available: false },
      }));
    }
    fetchData();
  }, [available_reservation_reload]);

  useEffect(() => {
    async function fetchData() {
      const geo = await getGeo(getToken);
      setUserGeo(geo);
      const featured_restaurants = await getFeaturedRestaurants(getToken);
      setFeaturedRestaurants(featured_restaurants);
      setRestData((prevRestData) => ({
        ...prevRestData,
        ...featured_restaurants.reduce((acc, obj) => {
          const key = obj.id;
          return { ...acc, [key]: obj };
        }, {}),
      }));

      const rest_ids = await getRestaurantIds(getToken);
      setRestIds(rest_ids);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (
        (user_requests === null || user_requests.length === 0) &&
        (user_reservations === null || user_reservations.length === 0) &&
        (more_restaurant_ids === null || more_restaurant_ids.length === 0)
      ) {
        return;
      }
      console.log("hello more restaurants");
      const allVenueIds = [
        ...more_restaurant_ids,
        ...(available_reservations || []).map(
          (reservation) => reservation.venue_id
        ),
        ...(user_requests || []).map((request) => request.venue_id),
        ...(user_reservations || []).map((reservation) => reservation.venue_id),
      ];

      const newVenueIds = allVenueIds.filter(
        (id) => !Object.keys(rest_data).includes(id)
      );

      if (newVenueIds.length === 0) {
        return; // No new restaurant IDs to fetch
      }

      const rest_details = await getRestaurantDetails(newVenueIds, getToken);
      setRestData((prevRestData) => ({
        ...prevRestData,
        ...rest_details.reduce((acc, obj) => {
          const key = obj.id;
          return { ...acc, [key]: obj };
        }, {}),
      }));
    }

    fetchData();
  }, [
    user_requests,
    user_reservations,
    available_reservations,
    more_restaurant_ids,
  ]);

  if (user === null) {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
        <div className="flex justify-center">
          <img src={loading_gif} className="opacity-50" />
        </div>
      </div>
    );
  }
  if (user && user.waitlist == true) {
    return <Navigate to="/wait" replace={true} />;
  }

  return (
    <div className="flex bg-gray-100 flex-col min-h-screen">
      <TopBar user={user} setCredentialPopup={setCredentials} />
      <div className="flex flex-grow justify-center overflow-y-auto">
        <div className="w-full flex justify-between overflow-y-auto">
          <RestaurantContext.Provider value={rest_ids}>
            <UserContext.Provider value={user}>
              <SideMenu
                setPopUp={setPopUp}
                setPage={setPage}
                page={which_page}
              />
              <div className="flex-grow p-6  overflow-y-auto no-scrollbar">
                {show_popup === true && (
                  <RequestPopup
                    handleRequestReload={handleRequestReload}
                    setPopUp={setPopUp}
                    restaurantList={rest_ids}
                    existingRequest={false}
                    setRestaurantIds={setRestaurantIds}
                    restaurant_data={rest_data}
                  />
                )}
                {show_credentials === true && (
                  <CredentialsPopup
                    handleUserReload={handleUserReload}
                    setPopUp={setCredentials}
                    user={user}
                  />
                )}
                <Outlet
                  context={{
                    reservation_requests: user_requests,
                    reservations: user_reservations,
                    available_reservations: available_reservations,
                    restaurant_data: rest_data,
                    handleRequestReload: handleRequestReload,
                    handleAvailableReservationReload:
                      handleAvailableReservationReload,
                    handleReservationReload: handleReservationReload,
                    loading: loading,
                    setRestaurantIds: setRestaurantIds,
                  }}
                />
              </div>
              <FeaturedRestaurantFeed
                featured_restaurants={featured_restaurants}
                handleRequestReload={handleRequestReload}
                user_geo={user_geo}
                show={true}
              />
            </UserContext.Provider>
          </RestaurantContext.Provider>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
