import { React, useState, useEffect, useContext } from "react";
import {
  FaUserFriends,
  FaClock,
  FaTimes,
  FaPen,
  FaCheck,
} from "react-icons/fa";
import { cancelReservationRequest } from "../api";
import { BiLinkExternal } from "react-icons/bi";
import RCard from "./RCard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RestaurantContext } from "../contexts";
import RequestPopup from "./RequestPopup";
import { useAuth } from "@clerk/clerk-react";

function ReservationRequestCard({ request, restaurant, handleRequestReload }) {
  const {
    party_size,
    use_tablestake_executor,
    date_time,
    status,
    min_time,
    max_time,
    id,
  } = request;
  const { getToken } = useAuth();

  const external_link =
    restaurant &&
    `https://resy.com/cities/${restaurant.city_code}/${restaurant.url_slug}`;
  const rest_ids = useContext(RestaurantContext);
  const [confirmed_cancel, setConfirmedCancel] = useState(false);
  const [show_edit, setEdit] = useState(false);
  const rest_data = {};
  if (restaurant) {
    rest_data[restaurant.id] = restaurant;
  }

  const handleClick = () => {
    if (confirmed_cancel) {
      async function cancelAndReload() {
        const cancel_success = await cancelReservationRequest(id, getToken);
        if (cancel_success) {
          toast.success("Cancelled your Request!");
        } else {
          toast.error("Failed to cancel your Request! Reload?");
        }
        await handleRequestReload();
      }
      cancelAndReload();
      setConfirmedCancel(false);
    } else {
      setConfirmedCancel(true); // Change to confirmed state on first click
    }
  };

  const button_row = (
    <div className="flex flex-row justify-end mt-3">
      <button
        className="flex items-center justify-center border-2 border-gray-500 hover:bg-gray-500 hover:text-white hover:border-gray-500 text-gray-500 py-2 px-2 rounded-full focus:outline-none"
        onClick={() => window.open(external_link, "_blank")}
      >
        <BiLinkExternal size={20} />
      </button>
      {["PENDING", "ACTIVE"].includes(status) && (
        <button
          className="flex items-center ml-2 justify-center border-2 border-red-400 hover:bg-red-400 hover:text-white hover:border-red-400 text-red-400 py-2 px-2 rounded-full focus:outline-none"
          onClick={handleClick}
          onBlur={() => setConfirmedCancel(false)}
        >
          {confirmed_cancel ? (
            <FaCheck className="mr-2" size={20} />
          ) : (
            <FaTimes size={20} />
          )}
          {confirmed_cancel ? "Confirm" : ""}
        </button>
      )}
      {["PENDING", "ACTIVE"].includes(status) && (
        <button
          className="flex items-center ml-2 justify-center border-2 border-green-400 hover:bg-green-400 hover:text-white hover:border-green-400 text-green-400 py-2 px-2 rounded-full focus:outline-none"
          onClick={() => setEdit(true)}
        >
          <FaPen size={18} />
        </button>
      )}
      {show_edit && (
        <RequestPopup
          handleRequestReload={handleRequestReload}
          setPopUp={setEdit}
          restaurantList={rest_ids}
          existingRequest={request}
          restaurant_data={rest_data}
        />
      )}
    </div>
  );

  return (
    <RCard
      r_data={request}
      r_header={"REQUEST"}
      restaurant={restaurant}
      buttons={button_row}
    />
  );
}

export default ReservationRequestCard;
