import { SignIn } from "@clerk/clerk-react";
import bg from "../assets/shattered-island.gif";

function SignInPage() {
  return (
    <div className="flex">
      <img src={bg} alt="Empty Table" className="w-1/2" />
      <div className="bg-gray-200 flex flex-row w-screen border-solid justify-center h-screen md:w-1/2">
        <div className="bg-gray-200 flex flex-col justify-center h-screen">
          <SignIn />
        </div>
      </div>
    </div>
  );
}

export default SignInPage;
