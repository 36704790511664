import { useContext, useState } from "react";
import empty_table from "../assets/empty_table.png";
import { FaPen } from "react-icons/fa";
import { RestaurantContext } from "../contexts";
import RequestPopup from "./RequestPopup";

function EmptyState({
  handleRequestReload,
  isRequest,
  rest_data,
  setRestaurantIds,
}) {
  const [popUp, setPopUp] = useState(false);
  const rest_ids = useContext(RestaurantContext);

  return (
    <div className="mt-5 flex w-full max-w-md overflow-hidden justify-center sm:flex-row">
      <div className="flex flex-col w-1/3 p-10 justify-center min-w-[230px] rounded-xl border-grey-200 border-2">
        <div className="flex justify-center">
          <img src={empty_table} className="opacity-50" />
        </div>
        <div className="flex justify-center">
          <h4 className="flex text-gray-800 mb-4 text-sm whitespace-nowrap justify-center">
            No {isRequest ? "requests" : "reservations"} currently!
          </h4>
        </div>
        <div className="flex justify-center">
          <button
            onClick={() => setPopUp(true)}
            className=" bg-green-500 hover:bg-green-600 py-3 px-3 rounded-full min-w-min justify-center items-center"
          >
            <div className="flex flex-row">
              <FaPen className="text-white flex-shrink-0" size={20} />
              <a className="ml-2 text-white text-md hitespace-nowrap">
                Make one
              </a>
            </div>
          </button>
        </div>

        {popUp && (
          <RequestPopup
            handleRequestReload={handleRequestReload}
            setPopUp={setPopUp}
            restaurantList={rest_ids}
            existingRequest={false}
            restaurant_data={rest_data}
            setRestaurantIds={setRestaurantIds}
          />
        )}
      </div>
    </div>
  );
}
export default EmptyState;
