import axios from "axios";
import Qs from "qs";
import axiosRetry from "axios-retry";
import { toast } from "react-toastify";

axiosRetry(axios, { retries: 3 });

async function getReservationRequests(getToken) {
  const user_owned_requests_url =
    process.env.REACT_APP_API_URL + "/requests/owned/";
  try {
    const response = await axios.get(user_owned_requests_url, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to retrieve requests!");
    return [];
  }
}

async function getRestaurantIds(getToken) {
  const restaurant_ids_url =
    process.env.REACT_APP_API_URL + "/restaurants/ids/";
  try {
    const response = await axios.get(restaurant_ids_url, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to retrieve restaurant ids!");
    return {};
  }
}

async function getRestaurantDetails(id_list, getToken) {
  let rest_axios = axios.create({
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: "repeat" }),
  });
  const rest_params = {
    q: id_list,
  };
  const restaurant_url =
    process.env.REACT_APP_API_URL + "/restaurants/details/";
  try {
    const response = await rest_axios.get(restaurant_url, {
      params: rest_params,
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to retrieve restaurant details!");
    return {};
  }
}

async function cancelReservationRequest(request_id, getToken) {
  const cancel_request_url =
    process.env.REACT_APP_API_URL + "/requests/cancel/";
  try {
    const response = await axios.post(cancel_request_url, null, {
      params: { request_id: request_id },
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to cancel request!");
  }
}
async function insertReservationRequest(request_object, getToken) {
  const request_url = process.env.REACT_APP_API_URL + "/requests/new/";
  try {
    const response = await axios.post(request_url, request_object, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to insert request!");
  }
}

async function updateReservationRequest(request_id, updates, getToken) {
  const update_request_url =
    process.env.REACT_APP_API_URL + "/requests/update/";
  try {
    const response = await axios.post(update_request_url, updates, {
      params: { request_id: request_id },
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to update request!");
  }
}

async function cancelReservation(reservation_id, getToken) {
  const cancel_res_url =
    process.env.REACT_APP_API_URL + "/reservations/cancel/";
  try {
    const response = await axios.post(cancel_res_url, null, {
      params: { reservation_id: reservation_id },
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to cancel reservation!");
  }
}
async function confirmReservation(reservation_id, getToken) {
  const confirm_res_url =
    process.env.REACT_APP_API_URL + "/reservations/confirm/";
  try {
    const response = await axios.post(confirm_res_url, null, {
      params: { reservation_id: reservation_id },
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to confirm reservation!");
  }
}

async function getReservations(getToken) {
  const user_owned_reservations_url =
    process.env.REACT_APP_API_URL + "/reservations/owned/";
  try {
    const response = await axios.get(user_owned_reservations_url, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to retrieve reservations!");
    return [];
  }
}

async function getAvailableReservations(getToken) {
  const available_res_url =
    process.env.REACT_APP_API_URL + "/reservations/available/";
  try {
    const response = await axios.get(available_res_url, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to retrieve available requests!");
    return [];
  }
}

async function claimReservation(reservation_id, getToken) {
  const claim_res_url = process.env.REACT_APP_API_URL + "/reservations/claim/";
  try {
    const response = await axios.post(claim_res_url, null, {
      params: { reservation_id: reservation_id },
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to claim reservations!");
  }
}

async function getFeaturedRestaurants(getToken) {
  const featured_rest_url =
    process.env.REACT_APP_API_URL + "/restaurants/featured/";
  try {
    const response = await axios.get(featured_rest_url, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to retrieve featured restaurants!");
    return [];
  }
}

async function getRestaurantReleaseInfo(restaurant_id, getToken) {
  const rest_time_url =
    process.env.REACT_APP_API_URL + "/restaurants/release_times/";
  try {
    const response = await axios.get(rest_time_url, {
      params: { restaurant_id: restaurant_id },
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to retrieve release info!");
    return [];
  }
}

async function getIP() {
  const ip_url = "https://api.ipify.org/?format=json";
  const response = await axios.get(ip_url);
  return response.data;
}

async function getGeo(getToken) {
  const geo_url = process.env.REACT_APP_API_URL + "/geo/";
  try {
    const ip_data = await getIP();
    const response = await axios.get(geo_url, {
      params: { ip: ip_data.ip },
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {}
}

async function getUser(getToken) {
  const user_url = process.env.REACT_APP_API_URL + "/user/details/";
  try {
    const response = await axios.get(user_url, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to retrieve user!");
    return {};
  }
}

async function submitResyCredentials(credentials, getToken) {
  const credentials_url = process.env.REACT_APP_API_URL + "/user/resy/";
  try {
    const response = await axios.post(credentials_url, credentials, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    return response.data;
  } catch {
    toast.error("Failed to submit credentials!");
  }
}

export {
  getReservationRequests,
  getRestaurantIds,
  getRestaurantDetails,
  cancelReservationRequest,
  insertReservationRequest,
  updateReservationRequest,
  cancelReservation,
  confirmReservation,
  getReservations,
  getAvailableReservations,
  claimReservation,
  getFeaturedRestaurants,
  getRestaurantReleaseInfo,
  getIP,
  getGeo,
  getUser,
  submitResyCredentials,
};
