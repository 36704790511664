import React from "react";

import { UserButton } from "@clerk/clerk-react";

function TopBar({ user, setCredentialPopup }) {
  function trigger() {
    setCredentialPopup(true);
  }
  return (
    <div className="flex bg-blue-950 min-h-[50px] max-h-[50px] flex-col justify-center w-full">
      <div className="flex justify-between px-4 w-full">
        <div className="text-white font-bold text-lg">TABLESTAKE</div>
        <div className="flex px-4">
          {user.resy_email === null && (
            <button
              onClick={trigger}
              className="bg-blue-500 hover:bg-blue-600  py-1 px-4 mr-2 rounded-full flex items-center"
            >
              <span className="text-white text-sm whitespace-nowrap">
                Add Resy Credentials ❗
              </span>
            </button>
          )}
          {user.resy_email !== null && (
            <button
              onClick={trigger}
              className="bg-gray-500 hover:bg-gray-600 py-1 px-4 mr-2 flex items-center"
            >
              <span className="text-white text-sm whitespace-nowrap">
                Resy Credentials Set ✅
              </span>
            </button>
          )}
          <UserButton />
        </div>
      </div>
    </div>
  );
}

export default TopBar;
