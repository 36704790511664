import "../App.css";
import { getDistance } from "geolib";
import RestaurantCard from "./RestaurantCard";

function FeaturedRestaurantFeed({
  featured_restaurants,
  handleRequestReload,
  geo_data,
  show,
}) {
  const start = geo_data
    ? { latitude: geo_data.lat, longitude: geo_data.lon }
    : null;
  const sorted_restaurants = geo_data
    ? featured_restaurants.sort(function (a, b) {
        return (
          getDistance(start, { latitude: a.lat, longitude: a.lon }) -
          getDistance(start, { latitude: b.lat, longitude: b.lon })
        );
      })
    : featured_restaurants;

  const display_rest = sorted_restaurants
    ? sorted_restaurants.slice(0, 4)
    : null;

  return (
    <div
      className={`mt-10 hidden w-1/4 max-w-sm overflow-auto mr-10 lg:block ${
        show ? "" : "opacity-0"
      } 2xl:mr-16`}
    >
      <div className="flex flex-col p-5 items-center max-h-min bg-slate-200 rounded-lg border-2">
        <h1 className="text-xl font-bold mb-4"> Featured </h1>
        {display_rest !== null &&
          display_rest.map((rest) => (
            <RestaurantCard
              key={rest.id}
              restaurant={rest}
              handleRequestReload={handleRequestReload}
              includePopup={true}
            />
          ))}
      </div>
    </div>
  );
}

export default FeaturedRestaurantFeed;
