import React from "react";
import ReactDOM from "react-dom/client";
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
} from "@clerk/clerk-react";

import "./index.css";
import App from "./pages/App";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import WaitPage from "./pages/Wait";
import NoPage from "./pages/NoPage";
import reportWebVitals from "./reportWebVitals";
import ReservationRequestFeed from "./components/ReservationRequestFeed";
import ReservationFeed from "./components/ReservationFeed";
import AvailableReservationFeed from "./components/AvailableReservationFeed";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function ClerkProviderWithRoutes() {
  const navigate = useNavigate();
  return (
    <ClerkProvider publishableKey={clerkPubKey} navigate={(to) => navigate(to)}>
      <Routes>
        <Route path="sign-in" element={<SignInPage />} />
        <Route path="sign-up" element={<SignUpPage />} />
        <Route path="wait" element={<WaitPage />} />
        <Route
          path="/"
          element={
            <>
              <SignedIn>
                <App />
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        >
          <Route path="/" element={<Navigate to="available" />} />
          <Route path="available" element={<AvailableReservationFeed />} />
          <Route path="requests" element={<ReservationRequestFeed />} />
          <Route path="reservations" element={<ReservationFeed />} />
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </ClerkProvider>
  );
}
root.render(
  <Router>
    <ClerkProviderWithRoutes />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
