import { React, useContext, useState } from "react";
import { FaLocationPin, FaUtensils } from "react-icons/fa";
import { BiLinkExternal } from "react-icons/bi";
import { BsGeoFill } from "react-icons/bs";
import { RestaurantContext } from "../contexts";
import RequestPopup from "./RequestPopup";

function RestaurantCard({
  restaurant,
  handleRequestReload,
  additionalInfo,
  includePopup,
}) {
  const { name, url_slug, city_code, location_name, tablestake_image, id } =
    restaurant;
  const existingRequest = { venue_id: id };
  const rest_ids = useContext(RestaurantContext);
  const external_link =
    restaurant && `https://resy.com/cities/${city_code}/${url_slug}`;
  const [show_popup, setPopUp] = useState(false);
  const rest_data = {};
  if (restaurant) {
    rest_data[restaurant.id] = restaurant;
  }

  return (
    <div className="mt-5 flex bg-white shadow-lg w-full max-w-md rounded-lg border-2 overflow-hidden flex-col sm:flex-row">
      <div className="p-4 flex flex-col justify-between w-full sm:w-3/5">
        <div>
          <h4 className="text-xs text-left underline text-gray-500">
            RESTAURANT
          </h4>
          <div className="flex flex-row justify-between">
            <div>
              <h2 className="text-md font-bold mb-2 text-left">{name}</h2>
            </div>
          </div>
          <p className="text-xs text-gray-700 text-left">
            <BsGeoFill className="inline-block mr-1" size={15} />
            {location_name}
          </p>
          {additionalInfo}
        </div>
        <div className="flex flex-row justify-end mt-2">
          <button
            className="flex items-center justify-center border-2 border-gray-500 hover:bg-gray-500 hover:text-white hover:border-gray-500 text-gray-500 py-2 px-2 rounded-full focus:outline-none"
            onClick={() => window.open(external_link, "_blank")}
          >
            <BiLinkExternal size={15} />
          </button>
          {includePopup && (
            <button
              className="flex items-center ml-2 justify-center border-2 border-green-400 hover:bg-green-400 hover:text-white hover:border-green-400 text-green-400 py-1 text-xs px-1 rounded-full focus:outline-none"
              onClick={() => setPopUp(true)}
            >
              <FaUtensils className="mr-2" size={15} /> BOOK
            </button>
          )}
        </div>
      </div>
      <div
        className="bg-gray-400 h-30 w-full sm:w-2/5"
        style={{
          backgroundImage: tablestake_image
            ? `url(${tablestake_image})`
            : "none",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
      {show_popup && includePopup && (
        <RequestPopup
          handleRequestReload={handleRequestReload}
          setPopUp={setPopUp}
          restaurantList={rest_ids}
          existingRequest={existingRequest}
          restaurant_data={rest_data}
        />
      )}
    </div>
  );
}

export default RestaurantCard;
