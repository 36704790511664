import ReservationRequestCard from "./ReservationRequestCard";
import "../App.css";
import EmptyState from "./EmptyState";
import { useOutletContext } from "react-router-dom";
import loading_gif from "../assets/loading.gif";

const active_statuses = ["PENDING", "ACTIVE"];

function ReservationRequestFeed() {
  const {
    reservation_requests,
    restaurant_data,
    handleRequestReload,
    loading,
    setRestaurantIds,
  } = useOutletContext();
  const curr_date = new Date();
  const present_requests = reservation_requests
    .filter((request) => active_statuses.includes(request.status))
    .sort((a, b) => new Date(a.date_time) - new Date(b.date_time));
  const past_requests = reservation_requests
    .filter(
      (request) =>
        (new Date(request.date_time) < curr_date ||
          !active_statuses.includes(request.status)) &&
        request.status !== "CANCELLED"
    )
    .sort((a, b) => new Date(b.date_time) - new Date(a.date_time));

  const isLoading = loading.request !== undefined ? loading.request : true;

  return (
    <div className="flex flex-col overflow-hidden items-center">
      <h1 className="text-2xl font-bold mb-4"> Your Requests</h1>
      {!isLoading &&
        present_requests.length === 0 &&
        EmptyState({
          handleRequestReload: handleRequestReload,
          isRequest: true,
          rest_data: restaurant_data,
          setRestaurantIds: setRestaurantIds,
        })}
      {isLoading && <img src={loading_gif} className="opacity-50" />}
      {!isLoading &&
        present_requests.map((request) => (
          <ReservationRequestCard
            key={request.id}
            request={request}
            restaurant={restaurant_data[request.venue_id]}
            handleRequestReload={handleRequestReload}
          />
        ))}
      {!isLoading && past_requests.length > 0 && (
        <h1 className="text-2xl font-bold mt-12 mb-4"> Past Requests</h1>
      )}
      {!isLoading &&
        past_requests.map((request) => (
          <ReservationRequestCard
            key={request.id}
            request={request}
            restaurant={restaurant_data[request.venue_id]}
            handleRequestReload={handleRequestReload}
          />
        ))}
    </div>
  );
}

export default ReservationRequestFeed;
