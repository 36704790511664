import ReservationCard from "./ReservationCard";
import "../App.css";
import EmptyState from "./EmptyState";
import { useOutletContext } from "react-router-dom";
import loading_gif from "../assets/loading.gif";

function ReservationFeed() {
  const {
    reservations,
    restaurant_data,
    handleReservationReload,
    handleRequestReload,
    loading,
    setRestaurantIds,
  } = useOutletContext();

  const present_reservations = reservations
    .filter(
      (res) =>
        new Date(res.date_time) > new Date() &&
        ["PLS_CONFIRM", "CONFIRMED", "BOOKED"].includes(res.status)
    )
    .sort((a, b) => new Date(a.date_time) - new Date(b.date_time));
  const past_reservations = reservations
    .filter((res) => !present_reservations.includes(res))
    .sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
  const isLoading =
    loading.reservation !== undefined ? loading.reservation : true;

  return (
    <div className="flex flex-col overflow-hidden items-center">
      <h1 className="text-2xl font-bold mb-4"> Your Reservations</h1>
      {!isLoading &&
        present_reservations.length === 0 &&
        EmptyState({
          handleRequestReload: handleRequestReload,
          isRequest: false,
          rest_data: restaurant_data,
          setRestaurantIds: setRestaurantIds,
        })}
      {isLoading && <img src={loading_gif} className="opacity-50" />}
      {!isLoading &&
        present_reservations.map((res) => (
          <ReservationCard
            key={res.id}
            reservation={res}
            restaurant={restaurant_data[res.venue_id]}
            handleReservationReload={handleReservationReload}
          />
        ))}
      {!isLoading && past_reservations.length > 0 && (
        <h1 className="text-2xl font-bold mt-12 mb-4"> Past Reservations</h1>
      )}
      {!isLoading &&
        past_reservations.map((res) => (
          <ReservationCard
            key={res.id}
            reservation={res}
            restaurant={restaurant_data[res.venue_id]}
            handleReservationReload={handleReservationReload}
          />
        ))}
    </div>
  );
}

export default ReservationFeed;
